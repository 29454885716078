import { useEffect, useState, lazy, Suspense } from "react";
import authentificate from "./utils/authentification";
import "antd/dist/antd.min.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import OpenReplay from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";

import "./App.css";
import { login } from "./utils/api";
import LoadingSpin from "./Components/LoadingSpin";

const EditorHome = lazy(() => import("./Pages/Editor/Home"));
const DashboardEditor = lazy(() => import("./Pages/Editor/DashboardEditor"));
const NotFound = lazy(() => import("./Pages/NotFound"));
const DashboardContainer = lazy(() => import("./Components/Dashboard"));

const tracker = new OpenReplay({
  projectKey: "Sl8iyfkDBJjRlHnG3LO7",
  onStart: ({ sessionID }) =>
    console.log("OpenReplay tracker started with session: ", sessionID),
  handleError: (error) => console.log("🔴 OpenReplay error: ", error),
});

tracker.use(trackerAssist({}));

tracker.start();

window.tracker = tracker;

function App() {
  const [authentificated, setAuthentificated] = useState(false);
  useEffect(() => {
    authentificate().then(() => {
      login().then((res) => {
        setAuthentificated(true);
        localStorage.setItem("jwtToken", res.data.token);
      });
    });
  }, []);
  if (!authentificated) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <LoadingSpin />
      </div>
    );
  }

  return (
    <Suspense
      fallback={
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <LoadingSpin />
        </div>
      }
    >
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Redirect />} />
          <Route exact path="/editor" element={<EditorHome />} />
          <Route
            exact
            path="/editor/:dashboardId"
            element={<DashboardEditor />}
          />
          <Route
            exact
            path="/editor/:dashboardId/:versionId"
            element={<DashboardEditor editingSavedVersion />}
          />
          <Route exact path="/:id" element={<DashboardContainer />} />
          <Route
            exact
            path="/embed/:id"
            element={<DashboardContainer displayHeader={false} />}
          />
          <Route
            exact
            path="/:id/preview"
            element={<DashboardContainer state="preview" />}
          />
          <Route
            exact
            path="/:id/:_id"
            element={<DashboardContainer state="save" />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

function Redirect() {
  const [loading, setLoading] = useState(true);
  const getUrlVars = () => {
    var vars = {};
    window.location.href.replace(
      /[#&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  };

  useEffect(() => {
    const state = getUrlVars()["state"];
    if (!!state) {
      const state_obj = {};
      state.split("&").forEach((x) => {
        const [key, value] = x.split("=");
        state_obj[key] = value;
      });
      window.location.replace(window.location.origin + state_obj.path);
    } else {
      setLoading(false);
    }
  }, []);
  return <>{!loading && <NotFound />}</>;
}

export default App;
